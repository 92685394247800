import { render, staticRenderFns } from "./record.vue?vue&type=template&id=81b20aec&scoped=true&"
import script from "./record.vue?vue&type=script&lang=js&"
export * from "./record.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81b20aec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\operation-management-background\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81b20aec')) {
      api.createRecord('81b20aec', component.options)
    } else {
      api.reload('81b20aec', component.options)
    }
    module.hot.accept("./record.vue?vue&type=template&id=81b20aec&scoped=true&", function () {
      api.rerender('81b20aec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/statisticsInvite/record.vue"
export default component.exports