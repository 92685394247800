import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { searchRecordKeys, friendsColumns } from "./config/index";
import { SysAccountApi } from "@/api";
export default {
  components: {},
  data: function data() {
    return {
      loading: false,
      downloading: false,
      searchKeys: searchRecordKeys,
      createForm: this.$form.createForm(this),
      columns: friendsColumns,
      total: 0,
      page: 1,
      size: 20,
      dataSource: []
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        return {};
      };
    },
    uid: function uid() {
      var _this$$route$query;
      return ((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.uid) || "";
    }
  },
  mounted: function mounted() {
    this.createForm.setFieldsValue({
      'inviteruid': this.uid
    });
    this.getList();
  },
  methods: {
    handleReset: function handleReset() {
      this.createForm.resetFields();
      this.page = 1;
      this.getList();
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this.loading = true;
          var params = _objectSpread(_objectSpread({}, values), {}, {
            size: _this.size,
            page: _this.page
          });
          SysAccountApi.getUserInvites(params).then(function (res) {
            _this.loading = false;
            _this.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this.total = (res === null || res === void 0 ? void 0 : res.total) || 0;
          }).catch(function () {
            _this.dataSource = [];
            _this.total = 0;
          }).finally(function (e) {
            setTimeout(function () {
              _this.loading = false;
            }, 500);
          });
        }
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    download: function download() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.downloading = true;
          SysAccountApi.downloadUserInvites(_objectSpread({}, values)).then(function (ret) {}).catch(function (e) {}).finally(function () {
            _this2.downloading = false;
          });
        }
      });
    }
  }
};